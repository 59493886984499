import { createTheme, Theme, TypographyVariantsOptions } from "@mui/material/styles";

/**
 * Helper function to override parts of a given theme
 * This is used in embedded components to override fontSize, fontFamily, colors, etc.
 */

const SERIF_FONT = "'Times New Roman', serif";
const SANS_SERIF_FONT = "Arial, sans-serif";

export default function getModifiedTheme(
  originalTheme: Theme,
  config?: {
    fontSize?: "small" | "medium" | "large";
    fontFamily?: "serif" | "sans-serif" | "default";
  },
) {
  // Early return if no modifications needed
  if ((!config?.fontSize || config.fontSize === "medium") && (!config?.fontFamily || config.fontFamily === "default")) {
    return originalTheme;
  }

  // Font size settings

  let fontSizeSettings: Partial<TypographyVariantsOptions> = {};

  if (config?.fontSize === "large") {
    fontSizeSettings = {
      large: {
        ...originalTheme.typography.large,
        fontSize: "20px",
      },
      medium: {
        ...originalTheme.typography.medium,
        fontSize: "16px",
      },
    };
  } else if (config?.fontSize === "small") {
    fontSizeSettings = {
      large: {
        ...originalTheme.typography.large,
        fontSize: "14px",
      },
      medium: {
        ...originalTheme.typography.medium,
        fontSize: "12px",
      },
    };
  }

  // Font family settings

  const createFontSettings = (originalTheme: Theme, fontFamily: string) => {
    return {
      fontFamily,
      // some build-in variants that require override
      body1: { ...originalTheme.typography.body1, fontFamily },
      button: { ...originalTheme.typography.button, fontFamily },
    };
  };

  let fontFamilySettings: Partial<TypographyVariantsOptions> = {};

  if (config?.fontFamily === "serif") {
    fontFamilySettings = createFontSettings(originalTheme, SERIF_FONT);
  } else if (config?.fontFamily === "sans-serif") {
    fontFamilySettings = createFontSettings(originalTheme, SANS_SERIF_FONT);
  }

  return createTheme({
    ...originalTheme,
    typography: {
      ...originalTheme.typography,
      ...fontSizeSettings,
      ...fontFamilySettings,
    },
  });
}
