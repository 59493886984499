/**
 * Datadog action names to measure performance
 */

export const datadogActions = {
  EC_LOOKUP_API_CALL: "ec_lookup_api_call", // how long embedded component lookup API call takes
  EC_ACCEPT_CHALLENGE_API_CALL: "ec_accept_challenge_api_call", // how long accept challenge API call takes
  EC_ALL_CARDS_API_CALL: "ec_all_cards_api_call", // how long all cards API call takes

  EC_AUTH_COMPONENT_RENDER_FROM_SDK: "ec_auth_component_render_from_sdk", // time between sdk message to render auth UI
  EC_CAROUSEL_COMPONENT_RENDER_FROM_SDK: "ec_carousel_component_render_from_sdk", // time between sdk message to render auth UI or carousel UI

  EC_CAROUSEL_COMPONENT_RENDER_FROM_OTP: "ec_carousel_component_render_from_otp", // time between otp completion to carousel UI rendering

  ENCRYPT_API_CALL: "encrypt_api_call", // how long our /encrypt call takes
  DECRYPT_API_CALL: "decrypt_api_call", // how long our /decrypt call takes
};

/**
 * Event Names for performance.mark
 * This allow us to measure the time between two events cross files
 */
export const performanceMarkers = {
  EC_AUTH_COMPONENT_RECEIVED_MESSAGE: "ec_auth_component_received_message", // when auth component receives message from SDK
  EC_CAROUSEL_COMPONENT_RECEIVED_MESSAGE: "ec_carousel_component_received_message", // when carousel component receives message from SDK
  EC_AUTH_OTP_COMPLETED: "ec_auth_otp_completed", // when otp is successfully completed
};
