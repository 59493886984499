"use client";

//export initMocks async func instead
import initMocks from "@msw/index";
import skipifyEvents from "@services/skipifyEvents";
import fp from "@services/fingerprint";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import useEmbeddedComponentStore from "@stores/embeddedComponentsStore";
import themes from "@themes/index";
import { LDContextCommon, useLDClient } from "launchdarkly-react-client-sdk";
import { useFlags } from "@hooks/ldWithOverrides";
import { useParams, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import useParentWindowComms from "./useParentWindowComms";
import getEnv from "@utils/getEnv";
import getModifiedTheme from "@utils/getModifiedTheme";

type ExtraContext = {
  [contexts: string]: LDContextCommon;
};

const useCommonInitialization = (extraContext: ExtraContext = {}, defaultTheme = themes.default) => {
  const routeParams = useParams();
  const searchParams = useSearchParams();
  const ldClient = useLDClient();
  const [analyticsReady, setAnalyticsReady] = useState(false);
  const asid = searchParams?.get("asid") as string;

  const [appReady, setAppReady] = useState(false);
  const [launchDarklyLoaded, setLaunchDarklyLoaded] = useState(false);

  const safeDevMode = ["local", "dev", "test", "sandbox"].includes(getEnv().ENV || "");

  const [fontSizeOverride, fontFamilyOverride] = useEmbeddedComponentStore((state) => [
    state.fontSize,
    state.fontFamily,
  ]);

  const theme = useMemo(() => {
    const themeOverrideParam = searchParams?.get("theme") || "";

    // Check if we can use the theme override from the url
    const canUseThemeOverride = safeDevMode && themeOverrideParam in themes;

    const baseTheme = canUseThemeOverride ? themes[themeOverrideParam] : defaultTheme;

    // modify theme if the value of embedded components store font setting changes
    return getModifiedTheme(baseTheme, { fontSize: fontSizeOverride, fontFamily: fontFamilyOverride });
  }, [defaultTheme, safeDevMode, searchParams, fontSizeOverride, fontFamilyOverride]);

  const [allowedDomains] = useMerchantConfigStore((state) => [state.allowedDomains]);

  const { sendLdFlags } = useParentWindowComms({
    allowedDomains,
  });

  const flags = useFlags();

  // Once all critical useEffects are run, flip appReady
  useEffect(() => {
    if (!launchDarklyLoaded) {
      return;
    }

    setAppReady(true);
  }, [launchDarklyLoaded]);

  // Initializing the application
  // - mocks
  useEffect(() => {
    if (!launchDarklyLoaded) return;

    const init = async () => {
      if (getEnv().API_MOCKING === "enabled") {
        await initMocks();
      }
    };

    init();
  }, [flags, launchDarklyLoaded]);

  // Initialize analytics
  useEffect(() => {
    const initAnalytics = async () => {
      if (!skipifyEvents.isLoaded) {
        await skipifyEvents.enableServices();
        await skipifyEvents.loadWithFingerprint();
        skipifyEvents.updateDatadogRumContext();
        if (asid) {
          skipifyEvents.setSessionId(+asid);
        }
        setAnalyticsReady(true);
      }
    };
    initAnalytics();
  }, [asid]);

  /**
   * This effect listens for changes in the device ID and the user and merges the changes into the launchdarkly context.
   * Listening at the _app(/pages) and GlobalContentWrapper (/app) level for now.
   */
  useEffect(() => {
    if (!ldClient || launchDarklyLoaded) {
      return;
    }

    if (routeParams === null) {
      return;
    }

    const initLd = async () => {
      try {
        const { visitorId: deviceId } = await fp.getFingerprint();
        const device = { key: deviceId, userAgent: window.navigator.userAgent };
        const merchant = routeParams.mid ? { key: routeParams.mid } : undefined;

        const ctx = {
          kind: "multi",
          user: { anonymous: true, key: deviceId },
          device,
          merchant,
          ...extraContext,
        };

        // Not catching errors for this right now
        await ldClient.identify(ctx);
      } catch (e) {
        console.warn("[LaunchDarkly] error identifying the user with LaunchDarkly:", (e as Error).message);
      } finally {
        setLaunchDarklyLoaded(true);
      }
    };

    initLd();
  }, [ldClient, extraContext, launchDarklyLoaded, routeParams, searchParams]);

  // Set the launchdarkly flags to the SDK and skipify events every time they change
  useEffect(() => {
    if (!launchDarklyLoaded) {
      return;
    }

    sendLdFlags({ ...flags });
    const flagValues = Object.entries(flags).reduce<string[]>((acc, [k, v]) => [...acc, `${k} : ${v}`], []);
    if (analyticsReady) skipifyEvents.identify(undefined, { Experiments: flagValues });
  }, [flags, launchDarklyLoaded, sendLdFlags, analyticsReady]);

  return {
    appReady,
    theme,
    safeDevMode,
  };
};

export default useCommonInitialization;
